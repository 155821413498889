import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import lemon from "../../Assets/Projects/lemon.png";
import melon from "../../Assets/Projects/melon.png";
import movie from "../../Assets/Projects/movie.png";
import spotify from "../../Assets/Projects/spotify.png";
import seina_cogs from "../../Assets/Projects/seina_cogs.png";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          MY <strong className="purple">WORKS </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "20px" }}>
          <Col md={5} className="project-card">
            <ProjectCard
              imgPath={melon}
              isBlog={false}
              title="MELON"
              description="The ultimate multi purpose discord bot with limitless capabilities and features."
              link="https://melonbot.io/"
            />
          </Col>
          <Col md={5} className="project-card">
            <ProjectCard
              imgPath={lemon}
              isBlog={false}
              title="LEMON"
              description="Lemon is a bot designed to keep your server safe and engage your members. It's moderation and logging suite keeps track of your members and moderators accountable. It also has auto-mod capabilities that filter outs certain types of behaviour when your staff is not available. Lemon has it's own weekly leveling system, color system, engaging fun commands and informative commands and more"
              link="https://github.com/japandotorg/Lemon/wiki"
            />
          </Col>
          <Col md={5} className="project-card">
            <ProjectCard
              imgPath={spotify}
              isBlog={false}
              title="Spotify Wrapped"
              description="Explore your listening habits throughout the year with Spotify's data visualization tools."
              link="https://spotify.japandotorg.work"
            />
          </Col>
          <Col md={5} className="project-card">
            <ProjectCard
              imgPath={movie}
              isBlog={false}
              title="Movie"
              description="Watch your favourite shows and movies."
              link="https://movie.japandotorg.work"
            />
          </Col>
          <Col md={5} className="project-card">
            <ProjectCard
              imgPath={seina_cogs}
              isBlog={false}
              title="Seina Cogs"
              description="My public cogs for Red-DiscordBot."
              link="http://github.com/japandotorg/Seina-Cogs"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
