import React from "react";
import Card from "react-bootstrap/Card";
import { FaRegHandPointRight } from "@react-icons/all-files/fa/FaRegHandPointRight";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Lemon (Yash) </span>
            from <span className="purple">India.</span>
            <br /> - Currently in the studio crafting my debut mixtape project.
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <FaRegHandPointRight /> Producing Music
            </li>
            <li className="about-activity">
              <FaRegHandPointRight /> Playing Games
            </li>
            <li className="about-activity">
              <FaRegHandPointRight /> Travelling
            </li>
          </ul>

          <p style={{ marginBlockEnd: 0, color: "rgb(155 126 172)" }}>
            "Proactive learning, even in the initial stages, minimizes future challenges"{" "}
          </p>
          <footer className="blockquote-footer">lemon</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
