import React from "react";
import GitHubCalendar from "react-github-calendar";
import { Row } from "react-bootstrap";

function Github() {
  const contribTime = contributions => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const shownMonths = 9;
  
    return contributions.filter(
      day => {
        const date = new Date(day.date);
        const monthOfDay = date.getMonth();
  
        return (
          date.getFullYear() === currentYear &&
          monthOfDay > currentMonth - shownMonths &&
          monthOfDay <= currentMonth
        );
      },
    );
  };
  const colourTheme = {
    background: "transparent",
    text: "#ffffff",
    grade4: "#8400b8",
    grade3: "#b22ff4",
    grade2: "#b265f6",
    grade1: "#c084f5",
    grade0: "#ecd9fc",
  };
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
      <h1 className="project-heading" style={{ paddingBottom: "20px" }}>
        MY <strong className="purple">CODE</strong> STATS
      </h1>
      <GitHubCalendar
        username="japandotorg"
        blockSize={15}
        blockMargin={5}
        transformData={contribTime}
        theme={colourTheme}
        fontSize={16}
      />
    </Row>
  );
}

export default Github;
